import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { CRow, CCol, CFormLabel, CButton } from '@coreui/react';
import VehiculosService from "src/services/VehiculosService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Card } from './Card.js'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { message, Switch } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { Link  } from "react-router-dom";

const moneyFormat = (value) => new Intl.NumberFormat().format(value);

const Edit = () =>{
    const [car, setCar] = useState({});
    var { id } = useParams();
    const [photos, setPhotos] = useState([]);
    const [isTriplo, setIsTriplo] = useState(false);
    const [vendido, setVendido] = useState(false);
    const [separate, setSeparate] = useState(false);
    const [estadoPro, setEstadoPro] = useState(0);
    const [status, setStatus] = useState(false);
    const [images, setImages] = useState([]);

    const getStatus = (_car) => {
        if (_car.status_im === 'inactive' && _car.published === 0) {
            return <span className="badge text-bg-danger">No</span>;
        } else if (_car.status_im === 'active' && _car.published === 0) {
            return <span className="badge text-bg-warning">Mercado libre</span>;
        } else if (_car.status_im === 'active' && _car.published === 1) {
            return <span className="badge text-bg-success">Si</span>;
        }
        return null; // Por si no cumple ninguna de las condiciones
    };

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setPhotos((prevCards) => {
            const newPhotosOrder = update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            });
            
            const urls = newPhotosOrder.slice(1).map(picture => picture.url).join(',');
            const data = {
                main: newPhotosOrder[0].url,
                pictures: urls, 
            };

            VehiculosService.saveImagens(data, id).then(response =>{
                console.log('Updated photo order:', response);
            }).catch(error => {
                console.error('Error updating photo order:', error);
            });            
    
            return newPhotosOrder;
        });
    }, [])

    const removeCard = (url) => {
        setPhotos((prevPhotos) => {
            const updatedPhotos = prevPhotos.filter(photo => photo.url !== url);
            let _name = url.split("/");
            let data = {
                folder_name: `auto_${id}`,
                file_name: _name[_name.length - 1],
            };
            VehiculosService.deletePhoto(data).then(resp => {
                // Update photos in the database
                const urls = updatedPhotos.slice(1).map(picture => picture.url).join(',');
                let _data = {
                    main: updatedPhotos[0].url,
                    pictures: urls
                };
                VehiculosService.saveImagens(_data, id).then(response => {
                    //
                });
            });
    
            return updatedPhotos;
        });
    };

    const renderCard = useCallback((card, index) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.url}
            moveCard={moveCard}
            removeCard={removeCard}
          />
        )
    }, [])

    const changeSeparar = (condicion) =>{
        let data = {
            id: id,
            value: condicion ? 1 : 0
        }
        setSeparate(condicion)
        VehiculosService.changeSeparate(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
    }

    const changeVendido = (condicion) =>{
        let data = {
            id: id,
            value: condicion ? 1 : 0
        }
        setVendido(condicion)
        VehiculosService.changeVendido(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
    }

    const changeIsTriplo = (condicion) =>{
        let data = {
            id: id,
            value: condicion ? 1 : 0
        }
        setIsTriplo(condicion)
        VehiculosService.changeIsTriplo(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
    }

    const validate = () => {
        // let validationErrors = {};
        // if (!carData.brand) validationErrors.brand = 'Marca es obligatoria';
        // if (!carData.year) validationErrors.year = 'Año es obligatorio';
        // if (!carData.model) validationErrors.model = 'Modelo es obligatorio';
        // if (!carData.linea) validationErrors.linea = 'Version es obligatorio';
        // if (!carData.plate) validationErrors.plate = 'Placa es obligatoria';

        // setErrors(validationErrors);
        // return Object.keys(validationErrors).length === 0; // Retorna true si no hay errores
    };

    const getCar = () =>{
        VehiculosService.get(id).then(resp =>{
            let _resp = resp.response
            setCar(resp.response);
            setSeparate(_resp.separate)
            setVendido(_resp.is_sale)
            setStatus(_resp.statusml == 'active' ? true : false)
            setStatus(_resp.status == 'active' ? true : false)
            setIsTriplo(_resp.is_triplo)

            let x = 0;
            if(_resp.nro_abonos){
                x = (_resp.nbonos * 100) / _resp.nro_abonos;
            }
            setEstadoPro(x)
            let photos = resp.response.pictures.split(',');
            let photosArray = [];
            photos.forEach((element, index) => {
                photosArray.push({ id: index + 1, url: element })
            });
            setTimeout(() => {
                setPhotos(photosArray);
            }, 3000);
            
        }).catch(error => console.log(error));
    }

    const handleChange = (e) => {
        setCar({
            ...car,
            [e.target.name]: e.target.value
        });
    };

    const guardar = () =>{
        let _data = {
            chasis: car.chasis,
            motor: car.motor,
            plate: car.plate
        }
        VehiculosService.saveData(_data, id).then(resp =>{
            message.success("Actualizado con exito")
        })
    }
    const changeStatus = (checked) =>{
        setStatus(checked)
        let data = {
            id: id,
            value: checked ? 'active' : 'inactive'
        }
        VehiculosService.changeStatus(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
        
    }
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map((file) => URL.createObjectURL(file));
        setImages([...images, ...newImages]);
    };

    const actualizarImagenes = () =>{
        // let _data = {
        //     main: item.img_main,
        //     pictures: ''
        // }        
        // _data.img_main = item.img_main;
        // console.log(mainImage.length);
        // if(mainImage.length > 0){
        //     const data = new FormData() 
        //     data.append('file', mainImage[0].file);                
        //     VehiculosService.uploadPhoto(data, "auto_"+id).then(response =>{
        //         _data.main = response.uri;
        //     })
        // }
        
        // if(otherImages.length > 0){
        //     setShowLoad(true);
        //     otherImages.forEach( async (element, index) => {
        //         const data = new FormData() 
        //         data.append('file', element.file); 
        //         await VehiculosService.uploadPhoto(data, "auto_"+id).then(response =>{
        //             _data.pictures += response.uri+",";
        //         })
        //         if((index + 1) == otherImages.length){
        //             _data.pictures = _data.pictures.slice(0, -1);
        //             _data.pictures = item.pictures+","+_data.pictures;
        //             console.log(_data);
        //             await VehiculosService.saveImagens(_data, id).then(response2 =>{                            
        //                 setTimeout(() => {
        //                     setShowLoad(false);
        //                     window.location.reload();
        //                 }, 3000);
        //             })
        //         }
        //     })
        // }
    }

    useEffect(() => {
        getCar();
    }, [])

    return(
        <>
            <CRow>
                <CCol xs={12}>
                    <div className='d-flex justify-content-between'>
                        <h4>{car.title}</h4>
                        <div className='d-flex align-items-center'>
                            { getStatus(car) }      
                            <div className="progress ms-2" role="progressbar" aria-label="Example with label" aria-valuenow={estadoPro} aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar" style={{ width: `${estadoPro}%`}}>{estadoPro}%</div>
                            </div>                      
                        </div>
                    </div>
                    <hr />
                    <form>
                        <CRow>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Marca</label>
                                    <input name="marca" value={car.make} placeholder="Marca" className='form-control' disabled />
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Año</label>
                                    <input name="year" value={car.year} placeholder="Año" className='form-control' disabled />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Modelo</label>
                                    <input name="modelo" value={car.model} placeholder="Modelo" className='form-control' disabled />
                                </div>
                            </CCol> 
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Linea</label>
                                    <input name="version" value={car.version} placeholder="Linea" className='form-control' disabled />
                                </div>
                            </CCol>                         
                        </CRow>
                        <CRow>     
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de vehículo</label>
                                    <input name="version" value={car.vehicle_type} placeholder="Tipo" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de carroceria</label>
                                    <input name="version" value={car.vehicle_body_type} placeholder="Carroceria" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Transmisión</label>
                                    <input name="version" value={car.transmission} placeholder="Transmisión" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de combustible</label>
                                    <input name="version" value={car.fuel_type} placeholder="Combustible" className='form-control' disabled/>
                                </div>
                            </CCol>                           
                        </CRow>
                        <CRow> 
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Color</label>
                                    <input name="version" value={car.color} placeholder="Color" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Kilometraje</label>
                                    <input name="version" value={ moneyFormat(car.kilometers) } placeholder="KM" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Chasis</label>
                                    <input name="chasis" value={car.chasis} placeholder="Chasis" className='form-control' onChange={handleChange}/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Motor</label>
                                    <input name="motor" value={car.motor} placeholder="Motor" className='form-control' onChange={handleChange}/>
                                </div>
                            </CCol>                           
                        </CRow>
                        <CRow>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Vehiculo vendido?</CFormLabel>
                                    <div>
                                        <Switch checked={vendido} onClick={ changeVendido } />
                                    </div>                    
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Vehiculo separado?</CFormLabel>
                                    <div>
                                        <Switch checked={separate} onClick={ changeSeparar } />
                                    </div>                    
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Estado? inactivo/ activo</CFormLabel>
                                    <div>
                                        <Switch checked={ status } onClick={ changeStatus } disabled/>
                                    </div>                    
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <CFormLabel>¿Vehículo de Triplo?</CFormLabel>
                                    <div>
                                        <Switch checked={isTriplo} onClick={ changeIsTriplo }/>
                                    </div>                    
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Precio</label>
                                    <input name="price" value={ moneyFormat(car.price)} placeholder="Precio" className='form-control'  onChange={handleChange}/>
                                </div>
                            </CCol>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Placa</label>
                                    <input name="plate" placeholder="Placa" className='form-control' value={ car.plate } onChange={handleChange} />
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={12}>
                                <div className='text-center'>
                                    <CButton onClick={guardar}>Guardar</CButton>
                                </div>
                            </CCol>
                        </CRow>
                        {
                            car.nro_abonos ?
                            <CRow>
                                <CCol xs={12}>
                                    <div className='form-group'>
                                        <label>
                                            Estado de venta&nbsp;
                                            <Link to={"/car-market/contado/editar/"+car.cm_id}>
                                                <FormOutlined className='iconCameraTable2' title='Editar'/>
                                            </Link>
                                        </label>
                                    </div>
                                </CCol>
                                <CCol xs={12}>
                                    <CRow>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Comprador</label>
                                                <p className="mb-0">{ moneyFormat(car.id_comprador) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Vendedor</label>
                                                <p className="mb-0">{ moneyFormat(car.id_vendedor) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Tipo de separacion</label>
                                                <p className="mb-0">{ (car.tipo_separacion) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Responsabilidad de gastos</label>
                                                <p className="mb-0">{ (car.resp_gastos) }</p>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow> : null
                        }
                        <CRow>
                            <CCol xs={12}>
                                <div className='form-group'>
                                    <label>Descripción</label>
                                    <ReactQuill value={ car.description } readOnly />
                                </div>
                            </CCol>
                        </CRow>
                    </form>                    
                </CCol>                
                <CCol xs={12}>
                    <hr />
                </CCol>
                <CCol xs={12}>
                    <div className='tet-center'>
                        <span className="badge text-bg-warning mt-3">Recuerde que la primera imagen del listado es la que corresponde a la portada.</span>
                    </div>
                    <div className="mb-3">                        
                        <label for="formFile" className="form-label">Selecciona la(s) foto(s)</label>
                        <input className="form-control" type="file" id="formFile" multiple onChange={handleImageUpload} />
                        <button type='button' onClick={ actualizarImagenes } className='btn btn-secondary mt-2'>Actualizar/ Subir fotos</button>
                    </div>
                    {
                        photos.length > 0 ?
                        <DndProvider backend={HTML5Backend}>
                            <CRow>
                            {
                                photos.map((card, i) => renderCard(card, i))
                            }
                            </CRow>   
                        </DndProvider> : ''
                    }
                </CCol>
            </CRow>
        </>
    )
}

export default Edit;