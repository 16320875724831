import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
import Prices from 'src/views/prices/Prices'
import Bitacora from 'src/views/bitacora/Bitacora'
import Ventas from 'src/views/ventas/Ventas'
import Register from 'src/views/register/Register'
import Vehiculos from 'src/views/vehiculos/Vehiculos'
import { useAuthUser } from 'react-auth-kit';
import Edit from 'src/views/vehiculos/NEW_Edit'
import ViewCar from 'src/views/vehiculos/ViewCar'
import Order from 'src/views/vehiculos/Order'
import View from 'src/views/driver/View'
import NewEdit from 'src/views/vehiculos/NewEdit'
import Traslado from 'src/views/traslado'
import Create from 'src/views/traslado/create'
//import Create from 'src/views/driver/Create'
import EditSol from 'src/views/traslado/edit'
import Emails from 'src/views/emails/Emails'
import CreateEmail from 'src/views/emails/Create'
import Index from 'src/views/notificaciones/Index'
import EditNot from 'src/views/notificaciones/Edit'
import New from 'src/views/notificaciones/New'
import Client from 'src/views/clientes/Client'
import AddCliente from 'src/views/clientes/AddCliente'
import Logmail from 'src/views/logmail/Logmail'
import Logs from 'src/views/logs/Logs'
import UploadData from 'src/views/upload/Upload'
import Fotos from 'src/views/traslado/fotos'
import Retomas from 'src/views/retomas'
import CrearRetoma from 'src/views/retomas/create'
import EditarRetoma from 'src/views/retomas/edit'
import VerRetoma from 'src/views/retomas/view'
import Dashboard from 'src/views/dashboard/Dashboard'
import Recepcion from 'src/views/recepcion'
import CrearRecepcion from 'src/views/recepcion/create'
import EditarRecepcion from 'src/views/recepcion/edit'
import VerRecepcion from 'src/views/recepcion/view'
import List from 'src/views/vehiculos/List'
import Actas from 'src/views/actas'
import CreateActa from 'src/views/actas/create'
import Tramites from 'src/views/tramites'
import CreateTramite from 'src/views/tramites/create'
import Bonos from 'src/views/bonos'
import Tripipe from 'src/views/tripipe' 
import CreateBono from 'src/views/bonos/alianzas'
import PruebaRuta from 'src/views/prueba-ruta'
import CreatePruebaRuta from 'src/views/prueba-ruta/create'
import CreateCar from 'src/views/vehiculos/CreateCar';
import IndexCont from 'src/views/contaduria/IndexCont';
import Contado from 'src/views/contaduria/Contado';
import EditarContado from 'src/views/contaduria/EditarContado';
import ContadoCredito from 'src/views/contaduria/ContadoCredito'
import Credito from 'src/views/contaduria/Credito'
import PartePago from 'src/views/contaduria/PartePago'
import Impuestos from 'src/views/contaduria/Impuestos'
import ImpuestoModificar from 'src/views/contaduria/ImpuestoModificar'

const AppContent = () => {
  const authUser = useAuthUser();

  return (
    <CContainer fluid>
      <Suspense fallback={<CSpinner color="primary" />}>
        {
          authUser().rol === 1 ?
          (
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/prices" element={<Prices />} />
              <Route path="/bitacora" element={<Bitacora />} />
              <Route path="/ventas" element={<Ventas />} />
              <Route path="/conductor" element={<Traslado />} />
              <Route path="/templates" element={<Emails />} />
              <Route path="/templates/nuevo/" element={<CreateEmail />} />
              <Route path="/vehiculos" element={<Vehiculos />} />
              <Route path="/vehiculos/editar/:id" element={<Edit />} />
              <Route path="/vehiculos/ordenar/:id" element={<Order />} />
              <Route path="/vehiculos/eliminar/:id" element={<NewEdit />} />
              <Route path="/vehiculos/lista" element={<List />} />
              <Route path="/vehiculos/crear" element={<CreateCar />} />
              <Route path="/vehiculos/ver/:id" element={<ViewCar />} />
              <Route path="/conductor/solicitud/" element={<Create />} />
              <Route path="/conductor/solicitud/editar/:id" element={<EditSol />} />
              <Route path="/conductor/solicitud/fotos/:id" element={<Fotos />} />
              <Route path="/conductor/consultar/:id" element={<View />} />
              <Route path='/notificaciones' element= {<Index />} />
              <Route path='/notificaciones/crear' element= {<New />} />
              <Route path='/notificaciones/editar/:id' element= {<EditNot />} />
              <Route path='/clientes' element= {<Client />} />
              <Route path='/clientes/agregar' element= {<AddCliente />} />
              <Route path='/logmail' element= {<Logmail />} />
              <Route path='/crons' element= {<Logs />} />
              <Route path='/upload' element= {<UploadData />} />
              <Route path='/retomas' element={ <Retomas /> } />
              <Route path='/retomas/crear' element={ <CrearRetoma /> } />
              <Route path='/retomas/editar/:id' element={ <EditarRetoma /> } />
              <Route path='/retomas/ver/:id' element={ <VerRetoma /> } />
              <Route path='/recepcion' element={ <Recepcion /> } />
              <Route path='/recepcion/crear' element={ <CrearRecepcion /> } />
              <Route path='/recepcion/editar/:id' element={ <EditarRecepcion /> } />
              <Route path='/recepcion/ver/:id' element={ <VerRecepcion /> } />
              <Route path='/actas-recepcion' element={ <Actas /> } />
              <Route path='/actas-recepcion/crear' element={ <CreateActa /> } />
              <Route path='/estado-de-tramites' element={ <Tramites /> } />
              <Route path='/estado-de-tramites/crear' element={ <CreateTramite /> } />
              <Route path='/bonos' element={ <Bonos /> } />
              <Route path='/alianzas' element={ <CreateBono /> } />
              <Route path='/prueba-ruta' element={ <PruebaRuta /> } />
              <Route path='/prueba-ruta/crear' element={ <CreatePruebaRuta /> } />
              <Route path='/tripipe' element={ <Tripipe /> } />
              <Route path='/car-market' element={ <IndexCont />} />
              <Route path='/car-market/contado/:id' element={ <Contado />} />
              <Route path='/car-market/contado/editar/:id' element={ <EditarContado />} />
              <Route path='/car-market/contado-credito/:id' element={ <ContadoCredito />} />
              <Route path='/car-market/credito/:id' element={ <Credito />} />
              <Route path='/car-market/parte-pago/:id' element={ <PartePago />} />
              <Route path='/impuestos' element={ <Impuestos />} />
              <Route path='/impuestos/modificar' element={ <ImpuestoModificar />} />
            </Routes>
          ):(
            authUser().rol === 2 ?
            (<Routes>
              <Route path="/registrar" element={<Register />} />
              <Route path="/bitacora" element={<Bitacora />} />
              <Route path="/ventas" element={<Ventas />} />
            </Routes>):
            (
              <Routes>
                <Route path="/conductor" element={<Traslado />} />
                <Route path="/conductor/solicitud/" element={<Create />} />
                <Route path="/conductor/consultar/:id" element={<View />} />
                <Route path="/conductor/solicitud/editar/:id" element={<EditSol />} />
                <Route path="/conductor/solicitud/fotos/:id" element={<Fotos />} />
                <Route path='/estado-de-tramites' element={ <Tramites /> } />
              </Routes>
            )
          )
        }

      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
