import React, {useRef, useEffect, useState} from 'react'
import { Form, Button, Col, Input, Checkbox, message } from 'antd';
import { CRow } from '@coreui/react';
import ActasService from 'src/services/ActasService';
import SignaturePad from 'react-signature-pad-wrapper';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { Stage, Layer, Circle, Image, Text } from 'react-konva';
import imageUrl from "../../assets/images/carro.png"
import useImage from 'use-image';
import Compressor from 'compressorjs';

const { TextArea } = Input;

const CreateActa = () => {
    const [form] = Form.useForm();
    const signaturePadRef = useRef(null);
    const signaturePadEmpRef = useRef(null);
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [image] = useImage(imageUrl);
    const [points, setPoints] = useState([]);
    const stageRef = useRef(null);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleStageClick = (e) => {
        const stage = stageRef.current.getStage();
        const pointerPosition = stage.getPointerPosition();
        setPoints([...points, pointerPosition]);
    };

    const compressImage = (imageURI) => {
        return new Promise((resolve, reject) => {
          new Compressor(imageURI, {
            quality: 0.6, // Ajusta la calidad de la imagen comprimida
            success(result) {
                console.log(result)
                resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });
    };

    const convertURIToBlob = (uri) => {
        return fetch(uri)
          .then((res) => res.blob());
    };

    const onFinish = (values) =>{
        if(signaturePadRef.current && signaturePadRef.current.isEmpty()){
            message.error(`Debe agregar una firma`);
        }else{ 
            let uri = stageRef.current.toDataURL(); 
            convertURIToBlob(uri).then((blob) => {
                //compressImage(blob).then((compressedURI) => {
                    let _data = {
                        nombres: values.nombres,
                        cedula: values.cedula,
                        telefono: values.telefono,
                        direccion: values.direccion,
                        nombres_cliente: values.nombres_cliente,
                        cedula_cliente: values.cedula_cliente,
                        telefono_cliente: values.telefono_cliente,
                        direccion_cliente: values.direccion_cliente,
                        placa: values.placa,
                        marca: values.marca,
                        modelo: values.modelo,
                        linea: values.linea,
                        soat: values.soat ? true : false,
                        tecnicomecanica: values.tecnicomecanica ? true : false,
                        tarjeta: values.tarjeta ? true : false,
                        manuales: values.manuales ? true : false,
                        herramientas: values.herramientas ? true : false,
                        perno_seguridad: values.perno_seguridad ? true : false,
                        repuesto: values.repuesto ? true : false,
                        kit_carretera: values.kit_carretera ? true : false,
                        botiquin: values.botiquin ? true : false,
                        gato: values.gato ? true : false,
                        cant_llaves: values.cant_llaves,
                        km_inicial: values.km_inicial,
                        observaciones: values.observaciones,
                        created_by: authUser().id,
                        firma_empleado: signaturePadEmpRef.current.toSVG(),
                        firma_cliente: signaturePadRef.current.toSVG()
                    }
                    const formData = new FormData();
                    Object.keys(_data).forEach((key) => {
                        formData.append(key, _data[key]);
                    });
                    formData.append('image', blob, 'canvas-image.png');
                    ActasService.save(formData).then(resp => {
                        message.success("Acta de recepción creada exitosamente");
                        setTimeout(() => {
                            let _url = resp.data;
                            //window.open(_url)
                            navigate("/actas-recepcion")
                        }, 4000);
                    })
                //});
            });
        }
    }

    const searchPlate = (event) =>{
        if(event.target.id === 'basic_placa' && event.target.value.length === 6){
            ActasService.getByPlate(event.target.value).then(resp =>{
                let _data = resp.data
                if(_data.length > 0){
                    _data = _data[0]
                    let _fields = {
                        marca: _data.make,
                        modelo: _data.model,
                        linea: _data.version,
                        km_inicial: _data.kilometers
                    }
                    form.setFieldsValue(_fields)
                }
            })
        }
    }

    const clearSignature = () => {
        if (signaturePadRef.current) {
          signaturePadRef.current.clear();
        }
    };

    const clearSignatureEmp = () => {
        if (signaturePadEmpRef.current) {
            signaturePadEmpRef.current.clear();
        }
    };

    const clearRectangles = () => {
        setPoints([]);
    };

    useEffect(() => {
        const handleResize = () => {
          setDimensions({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }else{
            let _fields = {
                cedula: authUser().cedula,
                nombres: authUser().names,
                telefono: authUser().phone
            }
            form.setFieldsValue(_fields)
        }
    }, []);

    return(
        <>
            <CRow>
                <Col span={24} className='text-center my-4'>
                    <h1>Crear acta de recepción</h1>
                </Col>
            </CRow>
            <Form name="basic" form={form} onFinish={onFinish} layout='vertical' onChange={ searchPlate }>
                <CRow>
                    <Col md={24} xs={24}>
                        <h4>Información del empleado</h4>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Nombres y Apellidos" name="nombres"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Nombres y apellidos' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Cédula de ciudadania" name="cedula"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Cédula de ciudadania' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Teléfono" name="telefono"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 3134569875' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Dirección" name="direccion"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la dirección' />
                        </Form.Item>
                    </Col>                    
                </CRow>
                <hr/>
                <CRow>
                    <Col md={24} xs={24}>
                        <h4>Información del cliente</h4>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Nombres y Apellidos" name="nombres_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Nombres y apellidos' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Cédula de ciudadania" name="cedula_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Cédula de ciudadania' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Teléfono" name="telefono_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 3134569875' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Dirección" name="direccion_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la dirección' />
                        </Form.Item>
                    </Col>
                </CRow>
                <hr/>
                <CRow>
                    <Col md={12} xs={24}>
                        <Form.Item label="Placa" name="placa"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: RNR549' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Marca" name="marca"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Mercedes-Benz' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Modelo" name="modelo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Clase SLK' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Linea" name="linea"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: SLK 200' />
                        </Form.Item>
                    </Col> 
                </CRow>
                <hr />
                <CRow>
                    <Col md={24} xs={24}>
                        <p>En este acto, se hace entrega material del vehículo con los siguientes documentos o inventario: </p>
                    </Col>
                    <Col md={24} xs={24}>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th> Documento </th>
                                        <th className='text-center'> Vigente </th>
                                    </tr>
                                </thead>
                                <tbody className='table-group-divider'>
                                    <tr>
                                        <td>SOAT (solicitar copia)</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="soat" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Revisión Tecnicomecanica</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="tecnicomecanica" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col md={24} xs={24}>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th colSpan={2} className='text-center'>INVENTARIO DEL VEHICULO </th>
                                    </tr>
                                </thead>
                                <tbody className='table-group-divider'>                                    
                                    <tr>
                                        <td>Tarjeta de Propiedad</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="tarjeta" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Manuales del Propietario</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="manuales" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Herramientas del Vehículo</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="herramientas" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Llave de pernos de seguridad</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="perno_seguridad" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Llanta de Repuesto </td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="repuesto" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Kit de Carretera </td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="kit_carretera" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Botiquín</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="botiquin" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Gato</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="gato" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Llaves" name="cant_llaves"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Este campo es requerido!',
                                                    }
                                                ]}>
                                                <Input type='number' className='text-end' placeholder='Ej: 2' />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Kilometraje inicial" name="km_inicial"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Este campo es requerido!',
                                                    }
                                                ]}>
                                                <Input type='text' className='text-end' placeholder='Ej: 10000' />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Otros/ Observaciones adicionales" name="observaciones">
                                                <TextArea rows={2} />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </CRow>
                <hr />
                <CRow className='my-3'>
                    <Col xs={24} span={24} className='text-center mx-auto'>
                        <Stage
                                width={380}
                                height={318}
                                onTouchStart={handleStageClick}
                                onClick={handleStageClick}
                                ref={stageRef}
                            >
                                <Layer>
                                    {image && <Image image={image} />}
                                    {points.map((point, index) => (
                                        <Circle
                                            key={index}
                                            x={point.x}
                                            y={point.y}
                                            radius={5}
                                            fill="black"
                                        />
                                    ))}
                                </Layer>
                            </Stage>                       
                    </Col>
                    <Col xs={24} span={24}>
                        <button type='button' onClick={clearRectangles}>Borrar</button>
                    </Col>
                </CRow>
                <CRow className="ffirmas">
                    <Col span={24} xs={24} className='text-center mx-auto'>
                        <label>Firma del cliente</label>
                        <SignaturePad ref={signaturePadRef} options={{penColor: 'rgb(0, 0, 0)'}} />   
                    </Col>
                    <Col span={24} xs={24}>
                        <button type='button' onClick={clearSignature}>Borrar firma</button>
                    </Col>
                </CRow>
                <CRow className="ffirmas">
                    <Col span={24} xs={24} className='text-center mx-auto'>
                        <label>Firma del empleado</label>
                        <SignaturePad ref={signaturePadEmpRef} options={{penColor: 'rgb(0, 0, 0)'}} />   
                    </Col>
                    <Col span={24} xs={24}>
                        <button type='button' onClick={clearSignatureEmp}>Borrar firma</button>
                    </Col>
                </CRow>
                <CRow className='my-4'>
                    <Col span={24} xs={24} md={24} className='text-center'>
                        <Button type='primary'  htmlType="submit">Generar</Button>
                    </Col>
                </CRow>
            </Form>
        </>    
    )
}

export default CreateActa;
